@import '~raleway-webfont';
@font-face {
  font-family: 'Blithe';
  src: url('../assets/blithe-webfont.woff2') format('woff2'), url('../assets/blithe-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

*, *::before, *::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 16px;
  background: #2b3038;
}

html, body {
  width: 100%;
  height: 100%;
}

body {
  display: flex;
  margin: 0;
  padding: 0;
  color: #fff;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.hide-scroll {
  width: calc(100% + 17px) !important;
  position: relative;
  left: 0;
  right: -17px;
}

.font-blithe {
  font-family: 'Blithe';
}

.font-raleway {
  font-family: 'Raleway';
  font-weight: normal;
}

.wf-loading h1, .wf-loading h2, .wf-loading h3, .wf-loading h4, .wf-loading h5, .wf-loading p, .wf-loading a, .wf-loading button {
  visibility: hidden;
}

.wf-active h1, .wf-active h2, .wf-active h3, .wf-active h4, .wf-active h5, .wf-active p, .wf-active a, .wf-active button, .wf-inactive h1, .wf-inactive h2, .wf-inactive h3, .wf-inactive h4, .wf-inactive h5, .wf-inactive p, .wf-inactive a, .wf-inactive button {
  visibility: visible;
}

.wf-inactive h1, .wf-inactive h2, .wf-inactive h3, .wf-inactive h4, .wf-inactive h5, .wf-inactive p, .wf-inactive a, .wf-inactive button {
  font-family: sans-serif;
}

.f-3 {
  font-size: 3rem;
}

.ma-logo {
  width: 178px;
  height: 178px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.ma-logo.loaded {
  opacity: 1;
}

.ma-logo.rsvp {
  text-align: center;
  margin: 40px auto 0px;
}

.ma-logo.rsvp svg {
  max-width: 160px;
  max-height: 160px;
  margin: auto;
}

#page-wrap {
  height: 100%;
  overflow: hidden;
  margin-left: calc(100vw - 100%);
}

#page-wrap.should-scroll {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

/* #page-wrap>div, #page-wrap>div>div {
  width: 100%;
  height: 100%;
} */

.page-content {
  padding-left: 16vw;
  padding-right: 16vw;
  width: 100vw;
  height: 100vh;
  -webkit-transform: translate3d(0,0,0);
}

@media (min-width: 1366px) {
  .page-content:not(.page-content-wide) {
    padding-left: 32vw;
    padding-right: 32vw;
  }
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.h-100vh {
  height: 100vh;
}

.h-min-content {
  height: min-content;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-italic {
  font-style: italic;
}

.letter-spacing-1 {
  letter-spacing: 1px;
}

.letter-spacing-2 {
  letter-spacing: 2px;
}

.font-sm {
  font-size: 14px !important;
}

.f-1 {
  font-size: 1rem;
}

.flex {
  display: -webkit-flex;
  display: flex;
}

.flex-direction-column {
  flex-direction: column;
}

.justify-content-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}

.position-relative {
  position: relative;
}

.position-fixed {
  position: fixed;
}

.z-index-1 {
  z-index: 1;
}

.block {
  display: block;
}

.m-0 {
  margin: 0;
}

.mt-0 {
  margin-top: 0;
}

.mt-20 {
  margin-top: 20px;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-40 {
  margin-bottom: 40px;
}

.p-0 {
  padding: 0;
}

.p-20 {
  padding: 20px;
}

.p-40 {
  padding: 40px;
}

.pt-80 {
  padding-top: 80px;
}

.pt-100 {
  padding-top: 100px;
}

.pt-260 {
  padding-top: 260px;
}

.pt-3 {
  padding-top: 3rem;
}

.pb-3 {
  padding-bottom: 3rem;
}

.pb-2 {
  padding-bottom: 2rem;
}

.pl-90 {
  padding-left: 90px;
}

.pb-80 {
  padding-bottom: 80px;
}

.list-style-none {
  list-style-type: none;
}

.pv-3 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.pv-2 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

h1 {
  line-height: 1.2;
}

p {
  line-height: 1.6;
}

a {
  color: #cc9786;
}

nav li a {
  display: block;
  padding: 1rem 0.5rem;
  text-decoration: none;
}

.top-nav li a {
  padding: 2rem 1.5rem;
  display: block;
  color: #fff;
}

.side-nav li a {
  color: #fff;
}

.is-active {
  font-weight: 700;
}

/* Position and sizing of burger button */

.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  right: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */

.bm-burger-bars {
  background: #fff;
}

/* Position and sizing of clickable cross button */

.bm-cross-button {
  right: 36px !important;
  top: 36px !important;
}


@media (max-width: 480px) {
  .bm-burger-button {
    right: 18px;
  }
}

@media (max-width: 375px) {
  .bm-burger-button {
    top: 10px;
    right: 10px;
  }
  .bm-cross-button {
    top: 14px !important;
    right: 14px !important;
  }
}

/* General sidebar styles */

.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */

.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */

.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */

.bm-item {
  display: inline-block;
}

/* Styling of overlay */

.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.background-cover {
  background-size: cover;
}

/* .page-background {
  background-repeat: no-repeat;
  background-position: center center;
  transition: background-position 0.6s cubic-bezier(0.22, 0.7, 0.53, 0.96);
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: -1;
  transform: translateZ(0);
  pointer-events: none;
}

.page-background::before {
  content: "";
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
} */

.bg-1 {
  background-size: cover;
}

@media (max-width: 450px) {
  .bg-1 {
    background-position: -50vw center;
  }
}

@media (min-width: 451px) and (max-width: 580px) {
  .bg-1 {
    background-position: -37vw center;
  }
}

/* .bg-1 {
  background-image: url('../assets/santorini-1.01.jpg');
}

.bg-2 {
  background-image: url('../assets/santorini-2.jpg');
}

.bg-3 {
  background-image: url('../assets/santorini-3.0.1.jpg');
}

.bg-4 {
  background-image: url('../assets/fort-common-3.jpg');
}

.bg-5 {
  background-image: url('../assets/ferris-wheel.jpg');
  background-size: cover;
} */

.button {
  min-height: 60px;
  margin-top: 23px;
  border: 1px solid #fff;
  transition: all 0.3s ease-in-out;
  padding: 0;
  color: #fff;
  background: rgba(43, 48, 56, .75);
  text-decoration: none;
  font-size: 1.5rem;
  padding: 1rem 2rem;
  display: block;
  transition: all 0.3s ease-in-out;
}

.button:hover {
  border-color: #ab9c95;
  background: rgba(43, 48, 56, 1);
  color: #fff;
  cursor: pointer;
}

.button.scale {
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
}

.button.scale:hover {
  transform: scale(1);
}

.button-tab-container {
  width: calc(100% + 2px);
  min-height: 60px;
}

.button-tab {
  padding: 2px;
  margin: 0 1px;
  border: 0;
}

.button-tab.active {
  background: #cc9786;
  font-weight: bold;
}

.fade-enter {
  opacity: 0.01;
}

.fade-enter-active {
  opacity: 1;
  transition: all 300ms ease-out;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0.01;
  transition: all 300ms ease-out;
}

iframe.google-map {
  width: 100%;
  height: 50vh;
}

@media (max-width: 400px) {

  .f-3 {
    font-size: 2rem;
  }

  iframe.google-map {
    height: 45vh;
  }

}