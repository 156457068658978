body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
.timeline li {
  display: flex;
  background: rgba(43, 48, 56, .75);
  margin: 5px 0;
}

.timeline li > * {
  display: flex;
}

.timeline .time {
  align-items: center;
  position: relative;
}

.timeline span {
  margin-left: -6.5rem;
  min-width: 6.25rem;
  padding-left: 1.5rem;
  height: 100%;
  background: hsla(213, 13%, 10%, 1);
  display: flex;
  align-items: center;
}

.timeline .time::before {
  content: "";
  background: #cc9786;
 
  width: 2px;
  height: 100%;
  left: -1px;
  position: relative;
}

.timeline .time::after {
  content: "";
  background: #cc9786; 
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  left: -10.5px;
  top: calc(50% - 10px);
}

.timeline-item {
  flex-direction: column;
  align-items: left;
  padding: 2rem;
}

.timeline-item h3 {
  position: relative;
  top: 10px;
}

.timeline-item p {
  color: rgba(255,255,255,0.8);
}

.form-modal {
  background: #2b3038;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 1200;
}

#page-wrap .circle-loader {
  margin-bottom: 2rem;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-left-color: #cc9786;
  -webkit-animation: loader-spin 1.2s infinite linear;
          animation: loader-spin 1.2s infinite linear;
  position: relative;
  display: inline-block;
  vertical-align: top;
  border-radius: 50%;
  width: 7em;
  height: 7em;
}

#page-wrap .load-complete {
  -webkit-animation: none;
  animation: none;
  border-color: #cc9786;
  -webkit-transition: border 500ms ease-out;
  transition: border 500ms ease-out;
}

.checkmark {
  display: none;
}

.checkmark.draw:after {
  -webkit-animation-duration: 800ms;
          animation-duration: 800ms;
  -webkit-animation-timing-function: ease;
          animation-timing-function: ease;
  -webkit-animation-name: checkmark;
          animation-name: checkmark;
  -webkit-transform: scaleX(-1) rotate(135deg);
          transform: scaleX(-1) rotate(135deg);
}

.checkmark:after {
  opacity: 1;
  height: 3.5em;
  width: 1.75em;
  -webkit-transform-origin: left top;
          transform-origin: left top;
  border-right: 3px solid #cc9786;
  border-top: 3px solid #cc9786;
  content: '';
  left: 1.75em;
  top: 3.5em;
  position: absolute;
}

@-webkit-keyframes loader-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes loader-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@-webkit-keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: 1.75em;
    opacity: 1;
  }
  40% {
    height: 3.5em;
    width: 1.75em;
    opacity: 1;
  }
  100% {
    height: 3.5em;
    width: 1.75em;
    opacity: 1;
  }
}

@keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: 1.75em;
    opacity: 1;
  }
  40% {
    height: 3.5em;
    width: 1.75em;
    opacity: 1;
  }
  100% {
    height: 3.5em;
    width: 1.75em;
    opacity: 1;
  }
}
.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0px auto;
  font-weight: 600;
  line-height: 36px;
}

.input-group.radio {
  background-color: #fff;
  color: #181818;
  display: block;
  margin: 23px 0 10px;
  position: relative;
}

.input-group.radio label {
  padding: 12px 70px 12px 20px;
  width: 100%;
  display: flex;
  text-align: left;
  color: #3c454c;
  cursor: pointer;
  position: relative;
  z-index: 7;
  -webkit-transition: color 200ms ease-in;
  transition: color 200ms ease-in;
  overflow: hidden;
  line-height: 1.6;
  min-height: 60px;
  align-items: center;
}

.input-group.radio label:before {
  width: 2vw;
  height: 2vh;
  border-radius: 50%;
  content: "";
  background-color: #d69e8b;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%) scale3d(0.5, 0.5, 0.5);
  transform: translate(-50%, -50%) scale3d(0.5, 0.5, 0.5);
  -webkit-transition: all 600ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: all 600ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  z-index: 1;
}

@media (min-width: 768px) {
  .input-group.radio label:before {
    -webkit-transform: translate(-50%, -50%) scale3d(1, 1, 1);
    transform: translate(-50%, -50%) scale3d(1, 1, 1);
  }
}

@media (min-width: 1200px) {
  .input-group.radio label:before {
    -webkit-transform: translate(-50%, -50%) scale3d(1.5, 1.5, 1.5);
    transform: translate(-50%, -50%) scale3d(1.5, 1.5, 1.5);
  }
}

.input-group.radio label:after {
  width: 32px;
  height: 32px;
  content: "";
  border: 2px solid #d1d7dc;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: 2px 3px;
  border-radius: 50%;
  z-index: 2;
  position: absolute;
  right: 30px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: pointer;
  -webkit-transition: all 200ms ease-in;
  transition: all 200ms ease-in;
}

.input-group.radio input:checked~label {
  color: #1e2227;
  text-shadow: 0px 1px 0px rgba(255, 255, 255, 0.5);
}

.input-group.radio input:checked~label:before {
  -webkit-transform: translate(-50%, -50%) scale3d(56, 56, 1);
  transform: translate(-50%, -50%) scale3d(56, 56, 1);
  z-index: -1;
  opacity: 1;
}

.input-group.radio input:checked~label:after {
  background-color: #1e2227;
  border-color: #1e2227;
}

.input-group.radio input {
  width: 32px;
  height: 32px;
  order: 1;
  z-index: 2;
  position: absolute;
  right: 28px;
  top: calc(50% - 16px);
  cursor: pointer;
  border: 0;
}

.input-group.radio input[type=radio] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.input-group.radio input:focus {
  outline: 0;
}

.input-group.radio input:invalid:focus+label:after {
  box-shadow: 0px 0px 18px #ff3131;
}

.float-label .control {
  position: relative;
  width: 100%;
  padding-top: 23px;
  padding-bottom: 10px;
}

.float-label .control.small {
  width: 30%;
  border-right: 1px solid #ddd;
}

.float-label .control.medium {
  width: 70%;
  padding-left: 10px;
}

.float-label .control:last-child {
  border: 0;
}

.float-label input[type='text'], .float-label textarea {
  display: block;
  width: 100%;
  border: 0;
  outline: 0;
  resize: none;
  height: 50px;
  padding: 0 20px;
  border-radius: 0;
}

.float-label input[type='text']+label, .float-label textarea+label {
  position: absolute;
  top: 10px;
  -webkit-transition: top 0.7s ease, opacity 0.7s ease;
  transition: top 0.7s ease, opacity 0.7s ease;
  opacity: 0;
  font-size: 13px;
  font-weight: 600;
  color: #ccc;
}

.float-label input[type='text']:valid+label {
  opacity: 1;
  top: -7px;
}

.float-label input[type='text']:focus+label, .float-label textarea:focus+label {
  color: #fff;
  opacity: 1;
  top: -7px;
}

.float-label textarea.message label {
  top: 10px;
  opacity: 0;
}

.float-label input::-webkit-input-placeholder {
  -webkit-transition: color 0.3s ease-in-out;
  transition: color 0.3s ease-in-out;
}

.float-label input:focus::-webkit-input-placeholder {
  color: transparent;
}

.float-label input:focus:-moz-placeholder {
  color: transparent;
}

textarea.message {
  height: 100px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.kids-only {
  background: #2f3642;
  padding: 20px;
  font-weight: 300;
}

.form-success-container {
  min-height: 280px;
}
@font-face {
  font-family: 'Blithe';
  src: url(/static/media/blithe-webfont.d03544a8.woff2) format('woff2'), url(/static/media/blithe-webfont.2c6527f5.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}

*, *::before, *::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 16px;
  background: #2b3038;
}

html, body {
  width: 100%;
  height: 100%;
}

body {
  display: flex;
  margin: 0;
  padding: 0;
  color: #fff;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.hide-scroll {
  width: calc(100% + 17px) !important;
  position: relative;
  left: 0;
  right: -17px;
}

.font-blithe {
  font-family: 'Blithe';
}

.font-raleway {
  font-family: 'Raleway';
  font-weight: normal;
}

.wf-loading h1, .wf-loading h2, .wf-loading h3, .wf-loading h4, .wf-loading h5, .wf-loading p, .wf-loading a, .wf-loading button {
  visibility: hidden;
}

.wf-active h1, .wf-active h2, .wf-active h3, .wf-active h4, .wf-active h5, .wf-active p, .wf-active a, .wf-active button, .wf-inactive h1, .wf-inactive h2, .wf-inactive h3, .wf-inactive h4, .wf-inactive h5, .wf-inactive p, .wf-inactive a, .wf-inactive button {
  visibility: visible;
}

.wf-inactive h1, .wf-inactive h2, .wf-inactive h3, .wf-inactive h4, .wf-inactive h5, .wf-inactive p, .wf-inactive a, .wf-inactive button {
  font-family: sans-serif;
}

.f-3 {
  font-size: 3rem;
}

.ma-logo {
  width: 178px;
  height: 178px;
  opacity: 0;
  -webkit-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
}

.ma-logo.loaded {
  opacity: 1;
}

.ma-logo.rsvp {
  text-align: center;
  margin: 40px auto 0px;
}

.ma-logo.rsvp svg {
  max-width: 160px;
  max-height: 160px;
  margin: auto;
}

#page-wrap {
  height: 100%;
  overflow: hidden;
  margin-left: calc(100vw - 100%);
}

#page-wrap.should-scroll {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

/* #page-wrap>div, #page-wrap>div>div {
  width: 100%;
  height: 100%;
} */

.page-content {
  padding-left: 16vw;
  padding-right: 16vw;
  width: 100vw;
  height: 100vh;
  -webkit-transform: translate3d(0,0,0);
}

@media (min-width: 1366px) {
  .page-content:not(.page-content-wide) {
    padding-left: 32vw;
    padding-right: 32vw;
  }
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.h-100vh {
  height: 100vh;
}

.h-min-content {
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-italic {
  font-style: italic;
}

.letter-spacing-1 {
  letter-spacing: 1px;
}

.letter-spacing-2 {
  letter-spacing: 2px;
}

.font-sm {
  font-size: 14px !important;
}

.f-1 {
  font-size: 1rem;
}

.flex {
  display: flex;
}

.flex-direction-column {
  flex-direction: column;
}

.justify-content-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}

.position-relative {
  position: relative;
}

.position-fixed {
  position: fixed;
}

.z-index-1 {
  z-index: 1;
}

.block {
  display: block;
}

.m-0 {
  margin: 0;
}

.mt-0 {
  margin-top: 0;
}

.mt-20 {
  margin-top: 20px;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-40 {
  margin-bottom: 40px;
}

.p-0 {
  padding: 0;
}

.p-20 {
  padding: 20px;
}

.p-40 {
  padding: 40px;
}

.pt-80 {
  padding-top: 80px;
}

.pt-100 {
  padding-top: 100px;
}

.pt-260 {
  padding-top: 260px;
}

.pt-3 {
  padding-top: 3rem;
}

.pb-3 {
  padding-bottom: 3rem;
}

.pb-2 {
  padding-bottom: 2rem;
}

.pl-90 {
  padding-left: 90px;
}

.pb-80 {
  padding-bottom: 80px;
}

.list-style-none {
  list-style-type: none;
}

.pv-3 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.pv-2 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

h1 {
  line-height: 1.2;
}

p {
  line-height: 1.6;
}

a {
  color: #cc9786;
}

nav li a {
  display: block;
  padding: 1rem 0.5rem;
  text-decoration: none;
}

.top-nav li a {
  padding: 2rem 1.5rem;
  display: block;
  color: #fff;
}

.side-nav li a {
  color: #fff;
}

.is-active {
  font-weight: 700;
}

/* Position and sizing of burger button */

.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  right: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */

.bm-burger-bars {
  background: #fff;
}

/* Position and sizing of clickable cross button */

.bm-cross-button {
  right: 36px !important;
  top: 36px !important;
}


@media (max-width: 480px) {
  .bm-burger-button {
    right: 18px;
  }
}

@media (max-width: 375px) {
  .bm-burger-button {
    top: 10px;
    right: 10px;
  }
  .bm-cross-button {
    top: 14px !important;
    right: 14px !important;
  }
}

/* General sidebar styles */

.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */

.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */

.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */

.bm-item {
  display: inline-block;
}

/* Styling of overlay */

.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.background-cover {
  background-size: cover;
}

/* .page-background {
  background-repeat: no-repeat;
  background-position: center center;
  transition: background-position 0.6s cubic-bezier(0.22, 0.7, 0.53, 0.96);
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: -1;
  transform: translateZ(0);
  pointer-events: none;
}

.page-background::before {
  content: "";
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
} */

.bg-1 {
  background-size: cover;
}

@media (max-width: 450px) {
  .bg-1 {
    background-position: -50vw center;
  }
}

@media (min-width: 451px) and (max-width: 580px) {
  .bg-1 {
    background-position: -37vw center;
  }
}

/* .bg-1 {
  background-image: url('../assets/santorini-1.01.jpg');
}

.bg-2 {
  background-image: url('../assets/santorini-2.jpg');
}

.bg-3 {
  background-image: url('../assets/santorini-3.0.1.jpg');
}

.bg-4 {
  background-image: url('../assets/fort-common-3.jpg');
}

.bg-5 {
  background-image: url('../assets/ferris-wheel.jpg');
  background-size: cover;
} */

.button {
  min-height: 60px;
  margin-top: 23px;
  border: 1px solid #fff;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  padding: 0;
  color: #fff;
  background: rgba(43, 48, 56, .75);
  text-decoration: none;
  font-size: 1.5rem;
  padding: 1rem 2rem;
  display: block;
  transition: all 0.3s ease-in-out;
}

.button:hover {
  border-color: #ab9c95;
  background: rgba(43, 48, 56, 1);
  color: #fff;
  cursor: pointer;
}

.button.scale {
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
}

.button.scale:hover {
  -webkit-transform: scale(1);
          transform: scale(1);
}

.button-tab-container {
  width: calc(100% + 2px);
  min-height: 60px;
}

.button-tab {
  padding: 2px;
  margin: 0 1px;
  border: 0;
}

.button-tab.active {
  background: #cc9786;
  font-weight: bold;
}

.fade-enter {
  opacity: 0.01;
}

.fade-enter-active {
  opacity: 1;
  -webkit-transition: all 300ms ease-out;
  transition: all 300ms ease-out;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0.01;
  -webkit-transition: all 300ms ease-out;
  transition: all 300ms ease-out;
}

iframe.google-map {
  width: 100%;
  height: 50vh;
}

@media (max-width: 400px) {

  .f-3 {
    font-size: 2rem;
  }

  iframe.google-map {
    height: 45vh;
  }

}
