.timeline li {
  display: flex;
  background: rgba(43, 48, 56, .75);
  margin: 5px 0;
}

.timeline li > * {
  display: flex;
}

.timeline .time {
  align-items: center;
  position: relative;
}

.timeline span {
  margin-left: -6.5rem;
  min-width: 6.25rem;
  padding-left: 1.5rem;
  height: 100%;
  background: hsla(213, 13%, 10%, 1);
  display: flex;
  align-items: center;
}

.timeline .time::before {
  content: "";
  background: #cc9786;
 
  width: 2px;
  height: 100%;
  left: -1px;
  position: relative;
}

.timeline .time::after {
  content: "";
  background: #cc9786; 
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  left: -10.5px;
  top: calc(50% - 10px);
}

.timeline-item {
  flex-direction: column;
  align-items: left;
  padding: 2rem;
}

.timeline-item h3 {
  position: relative;
  top: 10px;
}

.timeline-item p {
  color: rgba(255,255,255,0.8);
}
